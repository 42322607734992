<!--
 * ======================================
 * 说明： 年级组长  新增  修改
 * 作者： Silence
 * 文件： gradeteacherEdit.vue
 * 日期： 2023/8/13 0:49
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <el-form ref="form" :model="form" label-width="80px" :rules="rules">
    <el-form-item label="学期" prop="semesterId">
      <el-select :disabled="!!form.id" class="w-full" v-model="form.semesterId" filterable>
        <el-option v-for="(item,index) in semesterOptions" :label="item.semester_name" :value="item.id" :key="index"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item v-if="value.id" label="年级" prop="grade">
      <el-select class="w-full" v-model="form.grade">
        <el-option v-for="item in gradeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item v-else label="年级" prop="gradeList">
      <el-select class="w-full" multiple v-model="form.gradeList">
        <el-option v-for="item in gradeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="学科" prop="subjectId">
      <el-select class="w-full" v-model="form.subjectId">
        <el-option v-for="(item,index) in subjectOptions" :label="item['name']" :value="item['id']" :key="index"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="教师" prop="teacherId">
      <el-select class="w-full" v-model="form.teacherId" filterable>
        <el-option v-for="(item,index) in teacherOptions" :label="item['real_name']" :value="item['id']" :key="index">
          <div class="flex">
          <div>{{item.real_name}}</div>
          <div class="ml-auto">{{item.mobile}}</div>
        </div>
        </el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>
<script>
import { grade } from '@/utils/global.js'
export default {
    name: '',
    components: {},
    props: {
      value:Object
    },
    data() {
      return {
        gradeList:grade,
        teacherOptions:[],
        subjectOptions:[],
        semesterOptions:[],
        form:{
          id:'',
          grade:'',
          gradeList:[],
          subjectId:'',
          teacherId:'',
          semesterId:'',
        },
        rules:{
          gradeList:[{required: true, message: '请选择年级', trigger: 'change'}],
          grade:[{required: true, message: '请选择年级', trigger: 'change'}],
          subjectId:[{required: true, message: '请选择科目', trigger: 'change'}],
          teacherId:[{required: true, message: '请选择教师', trigger: 'change'}],
          semesterId:[{required: true, message: '请选择学期', trigger: 'change'}],
        }
      }
    },
    async mounted() {
      await this.handleLoadTeachers();  // 获取所有教师
      await this.handleLoadSubjects();  // 获取所有科目
      await this.handleLoadSemester();  // 获取所有学期
      if(this.value.id){
        await this.handleLoadForm(this.value.id);
      }else{
        this.form = {
          id:'',
          grade:'',
          gradeList:[],
          subjectId:'',
          teacherId:'',
          semesterId:'',
        }
      }
    },
    methods: {
      // 获取所有教师
      async handleLoadTeachers(){
        await this.$cloud.get('/teacher/list',{
          status:1,
          page:1,
          size:99999
        }).then(res=>{
          this.teacherOptions = res.children
        })
      },
      async handleLoadSubjects(){
        await this.$cloud.get('/subject/list',{
          page:1,
          size:99999
        }).then(res=>{
          this.subjectOptions = res.children
        })
      },
      async handleLoadSemester(){
        await this.$cloud.get('semester/list',{
          page:1,
          size:99999
        }).then(res=>{
          this.semesterOptions = res.children
        })
      },

      async handleLoadForm(id){
        await this.$cloud.get('lesson/detail',{id}).then(res=> {
          this.form.id = res.id
          this.form.grade = res.grade
          this.form.teacherId = res.teacher_id
          this.form.subjectId = res.subject_id
          this.form.semesterId = res.semester_id
        })
      },
      handleSubmit() {
        return new Promise((resolve, reject) => {
          this.$refs['form'].validate((valid) => {
            if (valid) {
              let api = '/lesson/create'
              //判断是否存在ID，如果存在ID，则是修改方法
              if (this.value.id) {
                api = '/lesson/edit'
              }
              console.log('api',api,this.form)
              this.$cloud.post(api, this.form).then(() => {
                resolve(true)
              }).catch(() => {
                reject(false)
              })
            } else {
              reject(false)
            }
          })
        })
      }
    }
  }
</script>
<style lang="scss" scoped></style>
